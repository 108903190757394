@font-face {
    font-family: "Fenice";
    font-weight: 400;
    font-display: swap;
    src: url('/fonts/fenice/fenicestd-regular-webfont.woff') format('woff'),
         url('/fonts/fenice/fenicestd-regular-webfont.ttf') format('truetype'),
         url('/fonts/fenice/fenicestd-regular-webfont.eot') format('embedded-opentype');
}

@font-face {
    font-family: "Didot";
    font-weight: 600;
    font-display: swap;
    src: url('/fonts/didot/Didot-HTF-B64-Bold.woff') format('woff'),
         url('/fonts/didot/Didot-HTF-B64-Bold.ttf') format('truetype'),
         url('/fonts/didot/Didot-HTF-B64-Bold.eot') format('embedded-opentype')
}

@font-face {
    font-family: "Didot";
    font-weight: 400;
    font-display: swap;
    src: url('/fonts/didot/Didot.woff2') format('woff2'),
         url('/fonts/didot/Didot.eot') format('embedded-opentype')
}

@font-face {
    font-family: "Didot";
    font-weight: 400;
    font-style: italic;
    font-display: swap;
    src: url('/fonts/didot/Didot-Italic.woff2') format('woff2'),
         url('/fonts/didot/Didot-Italic.eot') format('embedded-opentype')
}

@font-face {
    font-family: "Didot Number";
    font-weight: 400;
    font-display: swap;
    src: url('/fonts/didot/Didot-Modern.ttf') format('truetype')
}

@font-face {
    font-family: "Bodoni";
    font-weight: 400;
    font-display: swap;
    src: url('/fonts/bodoni/BodoniSvtyTwoSCITCTT-Book.woff') format('woff'),
         url('/fonts/bodoni/bod72scw.ttf') format('truetype')

}

@font-face {
    font-family: "Futura";
    font-weight: 300;
    font-display: swap;
    src: url('/fonts/futura/FuturaStd-Light.otf') format('opentype'), url('/fonts/futura/FuturaStd-Light.woff') format('woff');
}

@font-face {
    font-family: "Futura";
    font-weight: 400;
    font-display: swap;
    src: url('/fonts/futura/FuturaStd-Book.otf') format('opentype'), url('/fonts/futura/FuturaStd-Book.woff') format('woff');
}

@font-face {
    font-family: "Futura";
    font-weight: 500;
    font-display: swap;
    src: url('/fonts/futura/FuturaStd-Medium.otf') format('opentype'), url('/fonts/futura/FuturaStd-Medium.woff') format('woff');
}

@font-face {
    font-family: "Futura";
    font-weight: 600;
    font-display: swap;
    src: url('/fonts/futura/FuturaStd-Heavy.otf') format('opentype'), url('/fonts/futura/FuturaStd-Heavy.woff') format('woff');
}

@font-face {
    font-family: "Neutra";
    font-weight: 100;
    font-display: swap;
    src: url('/public/fonts/neutraface/Neutra-Thin.otf') format('opentype');
}

@font-face {
    font-family: "Neutra";
    font-weight: 300;
    font-display: swap;
    src: url('/fonts/neutraface/Neutra-Light.otf') format('opentype');
}

@font-face {
    font-family: "Neutra";
    font-weight: 400;
    font-display: swap;
    src: url('/fonts/neutraface/Neutra-Medium.otf') format('opentype');
}

@font-face {
    font-family: "Neutra";
    font-weight: 600;
    font-display: swap;
    src: url('/fonts/neutraface/Neutra-Bold.otf') format('opentype');
}

@font-face {
    font-family: "Centra";
    font-weight: 300;
    font-display: swap;
    src: url('/fonts/centra/Centra-No2-Light.otf') format('opentype'), url('/fonts/centra/Centra-No2-Light.woff2') format('woff2');
}

@font-face {
    font-family: "Centra";
    font-weight: 400;
    font-display: swap;
    src: url('/fonts/centra/Centra-No2.otf') format('opentype'), url('/fonts/centra/Centra-No2.woff2') format('woff2');
}

@font-face {
    font-family: "Centra";
    font-weight: 600;
    font-display: swap;
    src: url('/fonts/centra/Centra-No2-Bold.otf') format('opentype'), url('/fonts/centra/Centra-No2-Bold.woff2') format('woff2');
}

@font-face {
    font-family: "Proxima";
    font-weight: 400;
    font-display: swap;
    src: url('/fonts/proximanova/ProximaNova.woff2') format('woff'), url('/fonts/proximanova/ProximaNova.ttf') format('truetype');
}

@font-face {
    font-family: "Proxima";
    font-weight: 600;
    font-display: swap;
    src: url('/fonts/proximanova/ProximaNova-Bold.woff2') format('woff'), url('/fonts/proximanova/ProximaNova-Bold.ttf') format('truetype');
}

@font-face {
    font-family: "Chronicle";
    font-weight: 400;
    font-display: swap;
    src: url('/fonts/chronicle-display/Chronicle-Display-Roman.woff2') format('woff2'), url('/fonts/chronicle-display/Chronicle-Display-Roman.otf') format('opentype');
}

@font-face {
    font-family: "Chronicle";
    font-weight: 600;
    font-display: swap;
    src: url('/fonts/chronicle-display/Chronicle-Display-Semibold.woff2') format('woff2'), url('/fonts/chronicle-display/Chronicle-Display-Semibold.otf') format('opentype');
}

@font-face {
    font-family: "Chronicle";
    font-weight: 800;
    font-display: swap;
    src: url('/fonts/chronicle-display/Chronicle-Display-Black.woff2') format('woff2'), url('/fonts/chronicle-display/Chronicle-Display-Black.otf') format('opentype');
}

@font-face {
    font-family: "Optima";
    font-weight: 400;
    font-display: swap;
    src: url('/fonts/optima/Optima.woff2') format('woff2'), url('/fonts/optima/Optima.woff') format('woff');
}

@font-face {
    font-family: "Optima";
    font-weight: 600;
    font-display: swap;
    src: url('/fonts/optima/Optima-Medium.woff2') format('woff2'), url('/fonts/optima/Optima-Medium.woff') format('woff');
}

@font-face {
    font-family: "Sterling";
    font-weight: 400;
    font-display: swap;
    src: url('/fonts/sterling/SterlingDisplay.ttf') format('truetype'), url('/fonts/sterling/SterlingDisplay.ttf') format('woff2');
}

@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('/fonts/open-sans/OpenSans-Light.woff2') format('woff2'),
         url('/fonts/open-sans/OpenSans-Light.ttf') format('truetype')

}

@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('/fonts/open-sans/OpenSans-Regular.woff2') format('woff2'),
         url('/fonts/open-sans/OpenSans-Regular.ttf') format('truetype')
}

@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('/fonts/open-sans/OpenSans-Medium.woff2') format('woff2'),
         url('/fonts/open-sans/OpenSans-Medium.ttf') format('truetype')
}

@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('/fonts/open-sans/OpenSans-SemiBold.woff2') format('woff2'),
         url('/fonts/open-sans/OpenSans-SemiBold.ttf') format('truetype')
}

/*@font-face {*/
/*    font-family: "Open Sans";*/
/*    font-style: normal;*/
/*    font-weight: 700;*/
/*    font-display: swap;*/
/*    src: url('/fonts/open-sans/OpenSans-Bold.woff2') format('woff2'),*/
/*         url('/fonts/open-sans/OpenSans-Bold.ttf') format('truetype')*/
/*}*/

@font-face {
    font-family: "Red Bright";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('/fonts/red-bright/red-bright.woff') format('woff'),
         url('/fonts/red-bright/red-bright.otf') format('opentype')
}

@font-face {
    font-family: "Great Vibes";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('/fonts/great-vibes/GreatVibes-Regular.woff') format('woff'),
         url('/fonts/great-vibes/GreatVibes-Regular.otf') format('opentype')
}

@font-face {
    font-family: "Ballentines";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('/fonts/ballantines/ballantines-light.otf') format('opentype')
}

@font-face {
    font-family: "Lucia";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('/fonts/lucia/Lucia-BT.ttf') format('truetype')
}

.font-great-vibes {
    font-family: 'Great Vibes' !important;
}

.font-ballentines {
    font-family: 'Great Vibes' !important;
}